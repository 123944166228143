import { PrismicRichText } from '@prismicio/react';
import classNames from 'classnames';
import { Link } from 'gatsby';
import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

// @ts-expect-error png has no types
import KSBI from '../assets/images/kitchenvalley-ksbi.png';
// @ts-expect-error svg has no types
import Close from '../assets/svg/svg--close.inline.svg';
import { usePageContext } from '../providers/PageContext';
import { media } from '../styles/media';
import { Button } from './ui';

const FooterDiv = styled.footer<{ siteTag: string }>`
  .caption--footer {
    &:hover,
    &:focus {
      .caption--shown {
        display: none;
      }
      .caption--hidden {
        display: block;
        opacity: 1;
      }
      @media ${media.lgUp} {
        .caption--shown {
          display: block;
        }
      }
    }
    .caption--shown {
    }
    .caption--hidden {
      display: none;
      transition: all 0.3s ease-in;
      opacity: 0;
      @media ${media.lgUp} {
        display: block;
        border-inline-start: 1px solid var(--grey-4);
      }
    }
  }
  .seo-links-wrapper {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    scrollbar-color: transparent transparent;
    pointer-events: none;
    &::-webkit-scrollbar {
      display: none;
      height: 0px;
      background: rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb {
      background: rgba(0, 0, 0, 0);
      -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
    }
    &::-webkit-scrollbar-thumb:window-inactive {
      background: rgba(0, 0, 0, 0);
    }
    &::after {
      background: var(--grey-2);
      content: '';
      display: block;
      height 24px;
      left: 0;
      position: absolute;
      top: 0;
      width: 9999em;
    }
  }
  .seo-links {
    height: 24px;
    width: 9999em;
  }
  ${({ siteTag }) => {
    if (siteTag === 'site:profood-solutions') {
      return css`
        background-color: black !important;
        & * {
          color: white !important;
        }
        .seo-links-wrapper::after {
          background-color: black !important;
        }
      `;
    }
  }}
`;

const CookieBar = styled.div`
  position: fixed;
  padding-top: 22px;
  padding-bottom: 22px;
  width: 100%;
  bottom: 0;
  z-index: 10;
  background: var(--grey-2);
  transform: translateY(100%);
  transition: all 0.3s ease-in;
  p {
    a {
      text-decoration: underline;
      @media ${media.lgUp} {
        text-decoration: none;
      }
    }
  }
  &.is-active {
    transform: translateY(0);
  }
  .cookie--close {
    @media ${media.xlUp} {
      position: absolute;
      inset-inline-end: 20px;
    }
  }
`;

export default function Footer() {
  const pageContext = usePageContext();

  const {
    moduleData: {
      footerData: { prismicFooter: footer },
    },
    siteTag,
  } = pageContext;

  if (!footer) return null;

  switch (siteTag) {
    case 'site:kitchenvalley':
      return <FooterKitchenValley />;
    default:
      return <FooterDefault />;
  }
}

function FooterDefault() {
  const pageContext = usePageContext();

  function setCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  }

  function getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  useEffect(() => {
    const cookieClose = document.querySelector('.cookie--close');
    const cookieAccept = document.querySelector('.cookie--accept');
    const cookieBar = document.querySelector('#cookie-bar');

    cookieClose?.addEventListener('click', function (e) {
      e.preventDefault();
      cookieBar?.classList.remove('is-active');
      setCookie('ck-cookie', '/', 365);
    });

    cookieAccept?.addEventListener('click', function (e) {
      e.preventDefault();
      cookieBar?.classList.remove('is-active');
      setCookie('ck-cookie', '/', 365);
    });

    const cookie = getCookie('ck-cookie');
    if (!cookie) {
      cookieBar?.classList.add('is-active');
    }
  }, []);

  const {
    pageType,
    moduleData: {
      footerData: { prismicLandingPages, prismicFooter: footer },
    },
    global: {
      privacy_policy_link_text,
      privacy_policy_link,
      terms_of_service_link_text,
      terms_of_service_link,
      text_logo,
      cookie_policy_link,
      cookie_policy_link_label,
      cookie_banner_paragraph,
      accept_cookies_label,
    },
    siteTag,
  } = pageContext;

  return (
    <>
      <FooterDiv siteTag={siteTag} className="bg--grey-2">
        <div className="container pb-10 pt-20 md:pt-24 lg:pt-40">
          {pageType !== 'landing_page' && (
            <div className="grid grid-cols-2 gap-x-4 gap-y-6 pb-12 md:grid-cols-5 md:pb-16 lg:grid-cols-7 lg:pb-40">
              <div className="col-span-full mb-6 lg:col-span-2">
                <Link to="/" className="w-fit">
                  <img
                    src={text_logo?.url}
                    alt={text_logo?.alt}
                    className={classNames('db max-h-[24px]', {
                      '!max-h-8': siteTag === 'site:profood-solutions',
                    })}
                  />
                </Link>
              </div>
              {footer?.heading_1 && (
                <div>
                  <div className="eyebrow color--grey-5 mb-8 md:mb-10">
                    {footer.heading_1}
                  </div>
                  <ul>
                    {footer.links_1.map(
                      ({ link_text, link_url, open_in_new_tab }, index) => (
                        <li key={index}>
                          <a
                            href={link_url}
                            target={open_in_new_tab ? '_blank' : ''}
                            rel={open_in_new_tab ? 'noreferrer' : ''}
                            className="btn--text btn--text--large">
                            {link_text}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
              {footer?.heading_2 && (
                <div>
                  <div className="eyebrow color--grey-5 mb-8 md:mb-10">
                    {footer.heading_2}
                  </div>
                  <ul>
                    {footer.links_2.map(
                      ({ link_text, link_url, open_in_new_tab }, index) => (
                        <li key={index}>
                          <a
                            href={link_url}
                            target={open_in_new_tab ? '_blank' : ''}
                            rel={open_in_new_tab ? 'noreferrer' : ''}
                            className="btn--text btn--text--large">
                            {link_text}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
              {footer?.heading_3 && (
                <div>
                  <div className="eyebrow color--grey-5 mb-8 md:mb-10">
                    {footer.heading_3}
                  </div>
                  <ul>
                    {footer.links_3.map(
                      ({ link_text, link_url, open_in_new_tab }, index) => (
                        <li key={index}>
                          <a
                            href={link_url}
                            target={open_in_new_tab ? '_blank' : ''}
                            rel={open_in_new_tab ? 'noreferrer' : ''}
                            className="btn--text btn--text--large">
                            {link_text}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
              {footer?.heading_4 && (
                <div>
                  <div className="eyebrow color--grey-5 mb-8 md:mb-10">
                    {footer.heading_4}
                  </div>
                  <ul>
                    {footer.links_4.map(
                      ({ link_text, link_url, open_in_new_tab }, index) => (
                        <li key={index}>
                          <a
                            href={link_url}
                            target={open_in_new_tab ? '_blank' : ''}
                            rel={open_in_new_tab ? 'noreferrer' : ''}
                            className="btn--text btn--text--large">
                            {link_text}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
              {footer?.heading_5 && (
                <div>
                  <div className="eyebrow color--grey-5 mb-8 md:mb-10">
                    {footer.heading_5}
                  </div>
                  <ul>
                    {footer?.links_5?.map(
                      ({ link_text, link_url, open_in_new_tab }, index) => (
                        <li key={index}>
                          <a
                            href={link_url}
                            target={open_in_new_tab ? '_blank' : ''}
                            rel={open_in_new_tab ? 'noreferrer' : ''}
                            className="btn--text btn--text--large">
                            {link_text}
                          </a>
                        </li>
                      )
                    )}
                  </ul>
                </div>
              )}
              {footer.seo_links?.length > 0 && (
                <div className="seo-links-wrapper relative col-span-full">
                  <div className="seo-links flex">
                    {footer.seo_links.map((item, index) => (
                      <a
                        className="mr-2"
                        key={index}
                        href={item.seo_link?.url}
                        tabIndex={-1}
                        target={item.target ? item.target : ''}
                        rel={item.target === '_blank' ? 'noreferrer' : ''}>
                        {item.seo_link_text}
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
          )}
          <hr />
          <div className="flex items-center justify-between pt-4 rtl:flex-row-reverse">
            <div>
              <div
                className="caption caption--footer mb-0 flex items-center"
                dir="auto">
                <span className="caption--shown">
                  &copy;{new Date().getFullYear()}&nbsp;{footer.copyright_text}
                </span>
                <span className="caption--hidden color--grey-5 md:ml-4 md:pl-4 [&_*]:!m-0">
                  <PrismicRichText
                    field={footer.copyright_hover_text.richText}
                  />
                </span>
              </div>
            </div>
            <div className="hidden md:block">
              <ul className="flex justify-end">
                {[
                  [terms_of_service_link_text, terms_of_service_link],
                  [privacy_policy_link_text, privacy_policy_link],
                  [cookie_policy_link_label, cookie_policy_link],
                ].map(([link_text, link_url], index) =>
                  link_text && link_url ? (
                    <li className="caption ml-10" key={index}>
                      <Link to={link_url}>{link_text}</Link>
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          </div>
        </div>

        {false && siteTag === 'site:cloudkitchens' && (
          <CookieBar id="cookie-bar">
            <div className="container">
              <div className="flex items-center justify-center gap-x-6 lg:gap-x-12">
                <div className="caption color--grey-5 flex content-center [&_a]:!underline [&_p]:m-0">
                  <PrismicRichText field={cookie_banner_paragraph.richText} />
                </div>
                <div className="hidden pt-2 lg:block">
                  <Link
                    to={cookie_policy_link}
                    className="btn--text color--grey-5">
                    {cookie_policy_link_label}
                  </Link>
                </div>
                <Button
                  href="#"
                  variant="secondary"
                  className="cookie--accept"
                  size="sm">
                  {accept_cookies_label}
                </Button>
                <button
                  aria-label="close"
                  className="cookie--close ml-6 hidden md:block lg:ml-10">
                  <Close />
                </button>
              </div>
            </div>
          </CookieBar>
        )}

        <div style={{ display: 'none' }}>
          {prismicLandingPages.map((edge, index) => (
            <a key={index} href={`/locations/${edge.node.data.slug}`}>
              {edge.node.data.meta_title}
            </a>
          ))}
        </div>
      </FooterDiv>
    </>
  );
}

interface FooterLinkKitchenValleyProps {
  link_text: string;
  link_url: string;
  open_in_new_tab?: boolean;
}

const FooterLinkKitchenValley: React.FC<FooterLinkKitchenValleyProps> = ({
  link_text,
  link_url,
  open_in_new_tab = false,
}) => {
  return (
    <>
      {link_url ? (
        <li>
          <a
            href={link_url}
            target={open_in_new_tab ? '_blank' : ''}
            rel={open_in_new_tab ? 'noreferrer' : ''}
            className="btn--text btn--text--large !font-medium !leading-4 text-white">
            {link_text}
          </a>
        </li>
      ) : (
        <div>
          <span className="!font-medium !leading-4 text-white">
            {link_text}
          </span>
        </div>
      )}
    </>
  );
};

const FooterKitchenValley = () => {
  const pageContext = usePageContext();

  function setCookie(name, value, days) {
    let expires = '';
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = `; expires=${date.toUTCString()}`;
    }
    document.cookie = `${name}=${value || ''}${expires}; path=/`;
  }

  function getCookie(name) {
    const nameEQ = `${name}=`;
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
  }

  useEffect(() => {
    const cookieClose = document.querySelector('.cookie--close');
    const cookieAccept = document.querySelector('.cookie--accept');
    const cookieBar = document.querySelector('#cookie-bar');

    cookieClose?.addEventListener('click', function (e) {
      e.preventDefault();
      cookieBar?.classList.remove('is-active');
      setCookie('ck-cookie', '/', 365);
    });

    cookieAccept?.addEventListener('click', function (e) {
      e.preventDefault();
      cookieBar?.classList.remove('is-active');
      setCookie('ck-cookie', '/', 365);
    });

    const cookie = getCookie('ck-cookie');
    if (!cookie) {
      cookieBar?.classList.add('is-active');
    }
  }, []);

  const {
    pageType,
    moduleData: {
      footerData: { prismicLandingPages, prismicFooter: footer },
    },
    global: {
      privacy_policy_link_text,
      privacy_policy_link,
      terms_of_service_link_text,
      terms_of_service_link,
      text_logo,
      cookie_policy_link,
      cookie_policy_link_label,
    },
    siteTag,
  } = pageContext;

  return (
    <>
      <div className="bg--grey-2">
        <div>
          {pageType !== 'landing_page' && (
            <>
              <div className="container py-10 text-sm font-medium leading-4 md:py-[60px]">
                <div className="grid grid-cols-2 gap-x-8 gap-y-6 md:grid-cols-5 md:gap-x-[60px]">
                  <div className="col-span-full mb-6 md:col-span-2">
                    <Link to="/" className="w-fit">
                      <img
                        src={text_logo?.url}
                        alt={text_logo?.alt}
                        className={classNames('db max-h-[24px]', {
                          '!max-h-8': siteTag === 'site:profood-solutions',
                        })}
                      />
                    </Link>
                  </div>
                  {footer?.heading_1 && (
                    <div>
                      <div className="color--grey-5 mb-8 uppercase md:mb-10">
                        {footer.heading_1}
                      </div>
                      <ul>
                        {footer.links_1.map(
                          ({ link_text, link_url, open_in_new_tab }, index) => (
                            <li key={index}>
                              <a
                                href={link_url}
                                target={open_in_new_tab ? '_blank' : ''}
                                rel={open_in_new_tab ? 'noreferrer' : ''}
                                className="btn--text btn--text--large !font-medium !leading-4">
                                {link_text}
                              </a>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                  {footer?.heading_2 && (
                    <div>
                      <div className="color--grey-5 mb-8 uppercase md:mb-10">
                        {footer.heading_2}
                      </div>
                      <ul>
                        {footer.links_2.map(
                          ({ link_text, link_url, open_in_new_tab }, index) => (
                            <li key={index}>
                              <a
                                href={link_url}
                                target={open_in_new_tab ? '_blank' : ''}
                                rel={open_in_new_tab ? 'noreferrer' : ''}
                                className="btn--text btn--text--large !font-medium !leading-4">
                                {link_text}
                              </a>
                            </li>
                          )
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
              <div className="bg-secondary py-10 text-sm font-medium leading-4 md:py-[60px]">
                <div className="container gap-y-6 md:grid md:grid-cols-5 md:gap-x-8 md:gap-x-[60px]">
                  <div className="md:col-span-2">
                    <img
                      src={KSBI}
                      alt="Korean Satisfaction Brand Index 2022"
                      width={160}
                      height={162}
                    />
                  </div>
                  {footer?.heading_3 && (
                    <div className="">
                      <div className="color--white mb-4 mt-10 uppercase md:mb-10 md:mt-6">
                        {footer.heading_3}
                      </div>
                      <ul>
                        {footer.links_3.map(
                          ({ link_text, link_url, open_in_new_tab }, index) => (
                            <FooterLinkKitchenValley
                              link_text={link_text}
                              link_url={link_url}
                              open_in_new_tab={open_in_new_tab}
                              key={index}
                            />
                          )
                        )}
                      </ul>
                    </div>
                  )}
                  {footer?.heading_4 && (
                    <div className="">
                      <div className="color--white mb-4 mt-10 uppercase md:mb-10 md:mt-6">
                        {footer.heading_4}
                      </div>
                      <ul>
                        {footer.links_4.map(
                          ({ link_text, link_url, open_in_new_tab }, index) => (
                            <FooterLinkKitchenValley
                              link_text={link_text}
                              link_url={link_url}
                              open_in_new_tab={open_in_new_tab}
                              key={index}
                            />
                          )
                        )}
                      </ul>
                    </div>
                  )}
                  {footer?.heading_5 && (
                    <div className="">
                      <div className="color--white mb-4 mt-10 uppercase md:mb-10 md:mt-6">
                        {footer.heading_5}
                      </div>
                      <ul>
                        {footer?.links_5?.map(
                          ({ link_text, link_url, open_in_new_tab }, index) => (
                            <FooterLinkKitchenValley
                              link_text={link_text}
                              link_url={link_url}
                              open_in_new_tab={open_in_new_tab}
                              key={index}
                            />
                          )
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          <div className="container flex flex-col items-center justify-between gap-y-4 py-7 text-sm md:flex-row md:rtl:flex-row-reverse">
            <div>
              <div className="mb-0 flex items-center" dir="auto">
                <span>
                  &copy;{new Date().getFullYear()}&nbsp;{footer.copyright_text}
                </span>
              </div>
            </div>
            <div className="">
              <ul className="flex justify-end gap-x-[30px]">
                {[
                  [terms_of_service_link_text, terms_of_service_link],
                  [cookie_policy_link_label, cookie_policy_link],
                  [privacy_policy_link_text, privacy_policy_link],
                ].map(([link_text, link_url], index) =>
                  link_text && link_url ? (
                    <li key={index}>
                      <Link to={link_url}>{link_text}</Link>
                    </li>
                  ) : null
                )}
              </ul>
            </div>
          </div>
        </div>

        <div style={{ display: 'none' }}>
          {prismicLandingPages.map((edge, index) => (
            <a key={index} href={`/locations/${edge.node.data.slug}`}>
              {edge.node.data.meta_title}
            </a>
          ))}
        </div>
      </div>
    </>
  );
};
